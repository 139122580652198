import axios, { AxiosResponse } from 'axios';

const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL as string,
  headers: {
    'Content-Type': 'application/json',
  },
});

interface User {
  id: string;
  displayName: string;
  username: string;
  role: string;
  createdAt: string;
}

interface CrmCardContentConsents {
  consentId: string;
  consentText: string;
  hasCheckbox: boolean;
  mandatory: boolean;
}

interface CrmCardContent {
  consent: string;
  consents: CrmCardContentConsents[];
  mandatoryText: string;
  privacyPolicy: string;
  privacyPolicyAgreement: string;
  signatureAgreementPolicy: string;
}

interface CrmCardLabels {
  address: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  birthday: string;
  city: string;
  comment: string;
  country: string;
  date: string;
  email: string;
  firstName: string;
  lastName: string;
  nationality: string;
  personalInterests: string;
  phone: string;
  preferredLanguage: string;
  signature: string;
  state: string;
  title: string;
  zipCode: string;
}

interface CRMCard {
  id?: string;
  partition: string;
  boutiques: string[];
  cardId: string;
  content: CrmCardContent;
  country: string;
  createdAt: string;
  createdBy: string;
  labels: CrmCardLabels;
  language: string;
  mandatoryFields: string[];
  modifiedAt?: string;
  modifiedBy?: string;
  validationDate: string;
}

interface MasterDataBoutiqueAddress {
  address?: string;
  city?: string;
  countryCode: string;
  extraDescription?: string;
  line1: string;
  line2?: string;
  postalCode?: string;
  region?: string;
  sublocality?: string;
}

interface MasterDataBoutiqueGpsCoordinates {
  latitude: number;
  longitude: number;
}

interface MasterDataBoutiqueHolidayHours {
  date: string;
  isClosed: boolean;
}

interface MasterDataBoutiqueHours {
  openAt: string;
  closeAt: string;
  isClosed: boolean;
  iso: number;
}

interface MasterDataBoutique {
  id: string;
  partition: string;
  address: MasterDataBoutiqueAddress;
  bookingEnabled: boolean;
  boutiqueId?: string;
  email: string;
  gpsCoordinates?: MasterDataBoutiqueGpsCoordinates;
  holidayHours: MasterDataBoutiqueHolidayHours[];
  hours: MasterDataBoutiqueHours[];
  isVisible: boolean;
  mainPhone: string;
  name: string;
  owner: string;
  photoUrl: string;
  serviceCenter: boolean;
  timezone: string;
  type: string;
}

interface MasterDataCountry {
  id: string;
  partition: string;
  alpha3: string;
  name: string;
  translations: object;
}

interface MasterDataLanguage {
  id: string;
  partition: string;
  name: string;
}

const setAuthToken = (token: string) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const getUsers = async (): Promise<User[]> => {
  try {
    const response: AxiosResponse<User[]> = await api.get('/users');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch users:', error);
    throw error;
  }
};

export const getCRMCardById = async (id: string): Promise<CRMCard> => {
  try {
    const response: AxiosResponse<CRMCard> = await api.get(`/crm-card/${id}`);
    console.log('Fetching CRM Card with id', id, 'response:', response.data);
    return response.data;
  } catch (error) {
    console.error(`Failed to fetch CRM Card with id ${id}:`, error);
    throw error;
  }
};

export const getAllCRMCards = async (): Promise<CRMCard[]> => {
  try {
    const response: AxiosResponse<CRMCard[]> = await api.get('/crm-card/list');

    return response.data;
  } catch (error) {
    console.error('Failed to fetch CRM Cards:', error);
    throw error;
  }
};

export const createCRMCard = async (cardData: CRMCard): Promise<CRMCard> => {
  try {
    const response: AxiosResponse<CRMCard> = await api.post('/crm-card', cardData);
    return response.data;
  } catch (error) {
    console.error('Failed to create CRM Card:', error);
    throw error;
  }
};

export const updateCRMCard = async (id: string, cardData: Partial<CRMCard>): Promise<CRMCard> => {
  try {
    const response: AxiosResponse<CRMCard> = await api.put(`/crm-card/${id}`, cardData);
    return response.data;
  } catch (error) {
    console.error(`Failed to update CRM Card with id ${id}:`, error);
    throw error;
  }
};

export const deleteCRMCards = async (ids: string[]): Promise<{ deletedCount: number }> => {
  try {
    const response = await api.delete('/crm-card', {
      data: { ids },
    });
    return response.data;
  } catch (error) {
    console.error(`Failed to delete CRM Cards: ${error}`);
    throw error;
  }
};

export const getAllBoutiques = async (): Promise<MasterDataBoutique[]> => {
  try {
    const response: AxiosResponse<MasterDataBoutique[]> = await api.get('/master-data/boutiques');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch boutiques:', error);
    throw error;
  }
};

export const getAllCountries = async (): Promise<MasterDataCountry[]> => {
  try {
    const response: AxiosResponse<MasterDataCountry[]> = await api.get('/master-data/countries');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch countries:', error);
    throw error;
  }
};

export const getAllLanguages = async (): Promise<MasterDataLanguage[]> => {
  try {
    const response: AxiosResponse<MasterDataLanguage[]> = await api.get('/master-data/languages');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch languages:', error);
    throw error;
  }
};

export { setAuthToken, type User };
