export enum UserRole {
  ADMIN = 'admin',
  SUPPORT = 'support',
}

export const RoleList = [
  { value: UserRole.ADMIN, name: 'CRM HQ Admin' },
  { value: UserRole.SUPPORT, name: 'Support' },
];

export const RoleListPublic = [
  { value: UserRole.ADMIN, name: 'Administrator' },
  { value: UserRole.SUPPORT, name: 'Support' },
];
