import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import { APCommonPageLayout, APIcon, APLink, APNavigationLogo, AppLogoAp } from '@ap/design-system';
import { APNavigationProps } from '@ap/design-system/dist/components/APNavigation/types';
import AppRouter from '../../../config/router/AppRouter';
import BackToTop from '../BackToTop/BackToTop';
import BreadcrumbItem from '../Breadcrumb/BreadcrumbItem';
import { AppContext } from '../../../bootstrap/App';
import WelcomeWizard from '../WelcomeWizard/WelcomeWizard';
import { useLocation, useNavigate } from 'react-router-dom';
import { URI_USERS } from '../../../features/users/router/uri';
import Account from '../../../features/users/components/Account/Account';
import SettingsDialog from '../SettingsDialog/SettingsDialog';
import useSetCurrentUserData from '../../../features/users/services/useSetCurrentUserData';
import { UserTheme } from '../../../features/users/enums/UserTheme';
import DialogEasterEgg from '../DialogEasterEgg/DialogEasterEgg';
import useGetCurrentUser from '../../../features/users/services/useGetCurrentUser';
import useBreakpointDown from '../../hooks/useBreakpointDown';
import { BadgeRounded } from '@mui-symbols-material/w300';
import { URI_CARDS } from '../../../features/crm/router/uri';

const basePageTitle = 'AP CRM Cards';

interface LayoutContext {
  breadcrumbItems: BreadcrumbItem[];
  setBreadcrumbItems: (breadcrumbItems: BreadcrumbItem[]) => void;
  breadcrumbVisible: boolean;
  setBreadcrumbVisible: (visible: boolean) => void;
  containerSx: SxProps;
  setContainerSx: (sx: SxProps) => void;
  headerExtraContent?: React.ReactNode;
  setHeaderExtraContent: (children?: React.ReactNode) => void;
}

export const LayoutContext = createContext<LayoutContext>({
  breadcrumbItems: [],
  setBreadcrumbItems: () => {},
  breadcrumbVisible: true,
  setBreadcrumbVisible: () => {},
  containerSx: {},
  setContainerSx: () => {},
  headerExtraContent: undefined,
  setHeaderExtraContent: () => {},
});

function Layout() {
  const { darkMode, setDarkMode } = useContext(AppContext);
  const [breadcrumbItems, setBreadcrumbItems] = useState<Array<any>>([]);
  const [breadcrumbVisible, setBreadcrumbVisible] = useState(true);
  const [containerSx, setContainerSx] = useState<SxProps>({});
  const [headerExtraContent, setHeaderExtraContent] = useState<React.ReactNode | undefined>(undefined);
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
  const [countClick, setCountClick] = useState(0);
  const [eggOpen, setEggOpen] = useState(false);
  const [notificationDrawerOpen, setNotificationDrawerOpen] = useState(false);
  const [mobileMenuExpanded, setMobileMenuExpanded] = useState(false);

  const currentUser = useGetCurrentUser();
  const { setData } = useSetCurrentUserData();

  const navigate = useNavigate();
  const location = useLocation();

  const isMobile = useBreakpointDown('md');

  const isCurrentRoute = useMemo(
    () => (path: string) => {
      if (location?.pathname && path === '/') {
        return location.pathname === path;
      }
      return location?.pathname ? location.pathname.startsWith(`/${path}`) : false;
    },
    [location?.pathname],
  );

  const handleSwitchTheme = async () => {
    const newValue = !darkMode;

    setDarkMode(newValue);

    await setData([{ name: 'theme', value: newValue ? UserTheme.Dark : UserTheme.Light }]);
  };

  const handleClickVersion = () => {
    setCountClick(countClick + 1);
    if (countClick === 9) {
      setCountClick(0);
      setEggOpen(true);
    }
  };

  const handleClickNavigation = (uri: string) => {
    navigate(uri);

    if (isMobile) {
      setMobileMenuExpanded(false);
    }
  };

  const toggleNotificationsDrawer = () => {
    setNotificationDrawerOpen(!notificationDrawerOpen);
  };
  const navigationProps: APNavigationProps = useMemo(() => {
    return {
      bottomBarAvatarRender: <Account openSettings={() => setSettingsDialogOpen(true)} />,
      title: 'CRM Cards',
      expandedMenuLogo: <AppLogoAp />,
      collapsedMenuLogo: 'CAL',
      expandedMenuLogoBackgroundColor: 'ref/palette/blue',
      collapsedMenuLogoBackgroundColor: 'ref/palette/blue',
      collapsedMenuLogoFillColor: 'fd/icn/inversedforced',
      expandedMenuLogoFillColor: 'fd/icn/inversedforced',
      bottomBarButtonsProps: [
        {
          icon: <APIcon name={darkMode ? 'LightMode' : 'DarkMode'} filled={false} />,
          key: 'theme',
          tooltipProps: {
            title: `Turn ${darkMode ? 'on' : 'off'} the light`,
            placement: 'top',
          },
          onClick: handleSwitchTheme,
        },
        {
          icon: <APIcon name='Info' filled={false} />,
          key: 'about',
          tooltipProps: {
            title: (
              <Box
                sx={{
                  display: 'flex',
                  maxWidth: '400px', // Hardcoded in Figma
                  padding: `0px`,
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '4px', // Hardcoded in Figma
                }}
              >
                <Box>
                  Version{' '}
                  <APLink type='label' onClick={handleClickVersion}>
                    {import.meta.env.VITE_VERSION.toString()}
                  </APLink>{' '}
                  made with love
                  <br />
                  by the ITCS team in Le Brassus
                </Box>
              </Box>
            ),
            placement: 'top',
          },
        },
      ],
      links: [
        {
          label: 'CRM Cards',
          icon: <BadgeRounded />,
          menuKey: 'crm-cards',
          onClick: () => navigate(`/`),
          selected: isCurrentRoute(URI_CARDS),
        },
        {
          label: 'Users',
          icon: <APIcon name='SupervisorAccount' fontSize='large' />,
          menuKey: 'users',
          onClick: () => handleClickNavigation(`/${URI_USERS}`),
          selected: isCurrentRoute(URI_USERS),
        },
      ],
      multiAppSwitcherProps: {
        wifiCode: '',
        appLinks: [
          {
            appLogo: <APNavigationLogo backgroundColor='ref/neutral/whiteForced'>WAP</APNavigationLogo>,
            title: "What's AP",
            onClick: () => (window.location.href = 'https://intranet.audemarspiguet.com/'),
          },
          {
            appLogo: <APNavigationLogo backgroundColor='ref/palette/orange'>AC</APNavigationLogo>,
            title: 'my AP Academy',
            onClick: () => (window.location.href = 'https://audemarspiguet.csod.com/samldefault.aspx'),
          },
        ],
      },
      search: false,
      ...(isMobile && {
        // On mobile device, manually control the expanded state
        // It allows use to close the menu after touching on a link
        expanded: mobileMenuExpanded,
        onMenuExpanded: () => setMobileMenuExpanded(true),
        onMenuCollapsed: () => setMobileMenuExpanded(false),
      }),
    };
  }, [isCurrentRoute, navigate, darkMode, isMobile]);
  return (
    <LayoutContext.Provider
      value={{
        breadcrumbItems,
        setBreadcrumbItems,
        breadcrumbVisible,
        setBreadcrumbVisible,
        containerSx,
        setContainerSx,
        headerExtraContent,
        setHeaderExtraContent,
      }}
    >
      <HelmetProvider>
        <Helmet defaultTitle={basePageTitle} titleTemplate={`${basePageTitle} | %s`} />
        <APCommonPageLayout navbarProps={navigationProps} backgroundColor='fd/bg/container/secondary'>
          <AppRouter />
        </APCommonPageLayout>
        <SettingsDialog open={settingsDialogOpen} onClose={() => setSettingsDialogOpen(false)} />
        <DialogEasterEgg open={eggOpen} onClose={() => setEggOpen(false)} />
        <BackToTop />
        <WelcomeWizard />
        <ToastContainer
          position='bottom-right'
          autoClose={5000}
          theme={darkMode ? 'dark' : 'light'}
          hideProgressBar
          closeOnClick
          pauseOnHover
          draggable
        />
      </HelmetProvider>
    </LayoutContext.Provider>
  );
}

export default Layout;
